/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerAction
 */
export interface FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerAction {
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerAction
     */
    'type'?: FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerAction
     */
    'url'?: string;
}

export const FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerActionTypeEnum = {
    UNSPECIFIED: 'OVERFLOW_ACTION_TYPE_UNSPECIFIED',
    POST: 'OVERFLOW_ACTION_TYPE_POST',
    TARGET: 'OVERFLOW_ACTION_TYPE_TARGET',
    HIDE: 'OVERFLOW_ACTION_TYPE_HIDE',
    HIDE_WITH_TARGET: 'OVERFLOW_ACTION_TYPE_HIDE_WITH_TARGET',
    DO_NOTHING: 'OVERFLOW_ACTION_TYPE_DO_NOTHING'
} as const;

export type FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerActionTypeEnum = typeof FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerActionTypeEnum[keyof typeof FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerActionTypeEnum];


