/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistance
 */
export interface PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistance {
    /**
     * 
     * @type {boolean}
     * @memberof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistance
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistance
     */
    'unit'?: PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistanceUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistance
     */
    'value'?: number;
}

export const PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistanceUnitEnum = {
    UNSPECIFIED: 'DISTANCE_UNIT_UNSPECIFIED',
    METER: 'DISTANCE_UNIT_METER',
    KILOMETER: 'DISTANCE_UNIT_KILOMETER'
} as const;

export type PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistanceUnitEnum = typeof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistanceUnitEnum[keyof typeof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceSourceDistanceUnitEnum];


