/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface IntegrateSearchSuccessResponseCarDocsInnerCarDescription
 */
export interface IntegrateSearchSuccessResponseCarDocsInnerCarDescription {
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseCarDocsInnerCarDescription
     */
    'driveDistance'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseCarDocsInnerCarDescription
     */
    'modelYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseCarDocsInnerCarDescription
     */
    'carName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrateSearchSuccessResponseCarDocsInnerCarDescription
     */
    'changeHistories'?: Array<IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum>;
}

export const IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum = {
    UNSPECIFIED: 'CAR_CHANGE_HISTORY_TYPE_UNSPECIFIED',
    REGISTER: 'CAR_CHANGE_HISTORY_TYPE_REGISTER',
    CAR_NO: 'CAR_CHANGE_HISTORY_TYPE_CAR_NO',
    OWNER: 'CAR_CHANGE_HISTORY_TYPE_OWNER'
} as const;

export type IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum = typeof IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum[keyof typeof IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum];


