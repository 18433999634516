/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { IntegrateSearchSuccessResponseCarDocsInnerCarDescription } from './integrate-search-success-response-car-docs-inner-car-description';
import { ProtobufTimestamp } from './protobuf-timestamp';
import { SearchBigPicture } from './search-big-picture';

/**
 * 
 * @export
 * @interface IntegrateSearchSuccessResponseCarDocsInner
 */
export interface IntegrateSearchSuccessResponseCarDocsInner {
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'displayPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'userStatus'?: IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'carStatus'?: IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'carOptionNames'?: Array<IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum>;
    /**
     * 
     * @type {SearchBigPicture}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'firstImage'?: SearchBigPicture;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseCarDocsInnerCarDescription}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'carDescription'?: IntegrateSearchSuccessResponseCarDocsInnerCarDescription;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'pullUpCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'chatCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'voteCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'viewCount'?: number;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'createdAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'updatedAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof IntegrateSearchSuccessResponseCarDocsInner
     */
    'publishedAt'?: ProtobufTimestamp;
}

export const IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum = {
    UNSPECIFIED: 'CAR_USER_STATUS_UNSPECIFIED',
    WHITE: 'CAR_USER_STATUS_WHITE',
    NORMAL: 'CAR_USER_STATUS_NORMAL',
    DORMANCY: 'CAR_USER_STATUS_DORMANCY',
    SECESSION: 'CAR_USER_STATUS_SECESSION',
    BLOCKED: 'CAR_USER_STATUS_BLOCKED',
    BLACK: 'CAR_USER_STATUS_BLACK',
    UNKNOWN: 'CAR_USER_STATUS_UNKNOWN'
} as const;

export type IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum = typeof IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum[keyof typeof IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum];
export const IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum = {
    UNSPECIFIED: 'CAR_STATUS_UNSPECIFIED',
    SALE: 'CAR_STATUS_SALE',
    RESERVE: 'CAR_STATUS_RESERVE',
    TRADED: 'CAR_STATUS_TRADED'
} as const;

export type IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum = typeof IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum[keyof typeof IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum];
export const IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum = {
    UNSPECIFIED: 'CAR_OPTION_NAME_UNSPECIFIED',
    SMOKING_FREE: 'CAR_OPTION_NAME_SMOKING_FREE'
} as const;

export type IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum = typeof IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum[keyof typeof IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum];


