/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiV1IntegrateKarrotGroupRegionRangesGet200Response } from '../models';
// @ts-ignore
import { ApiV1IntegrateSearchPost200Response } from '../models';
// @ts-ignore
import { ApiV1IntegrateSearchPostRequest } from '../models';
/**
 * IntegrateApi - axios parameter creator
 * @export
 */
export const IntegrateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 통합검색 모임 컬렉션 지역 범위 필터
         * @summary 통합검색 모임 컬렉션 지역 범위 필터
         * @param {string} regionId 
         * @param {'view'} rangeType 
         * @param {'geojson'} [polygonFormat] 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrateKarrotGroupRegionRangesGet: async (regionId: string, rangeType: 'view', polygonFormat?: 'geojson', xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('apiV1IntegrateKarrotGroupRegionRangesGet', 'regionId', regionId)
            // verify required parameter 'rangeType' is not null or undefined
            assertParamExists('apiV1IntegrateKarrotGroupRegionRangesGet', 'rangeType', rangeType)
            const localVarPath = `/api/v1/integrate/karrot_group/region_ranges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (polygonFormat !== undefined) {
                localVarQueryParameter['polygonFormat'] = polygonFormat;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 통합탭 검색 결과 조회
         * @summary 통합탭 검색 결과 조회
         * @param {ApiV1IntegrateSearchPostRequest} apiV1IntegrateSearchPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrateSearchPost: async (apiV1IntegrateSearchPostRequest: ApiV1IntegrateSearchPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiV1IntegrateSearchPostRequest' is not null or undefined
            assertParamExists('apiV1IntegrateSearchPost', 'apiV1IntegrateSearchPostRequest', apiV1IntegrateSearchPostRequest)
            const localVarPath = `/api/v1/integrate/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV1IntegrateSearchPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrateApi - functional programming interface
 * @export
 */
export const IntegrateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrateApiAxiosParamCreator(configuration)
    return {
        /**
         * 통합검색 모임 컬렉션 지역 범위 필터
         * @summary 통합검색 모임 컬렉션 지역 범위 필터
         * @param {string} regionId 
         * @param {'view'} rangeType 
         * @param {'geojson'} [polygonFormat] 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrateKarrotGroupRegionRangesGet(regionId: string, rangeType: 'view', polygonFormat?: 'geojson', xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1IntegrateKarrotGroupRegionRangesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrateKarrotGroupRegionRangesGet(regionId, rangeType, polygonFormat, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 통합탭 검색 결과 조회
         * @summary 통합탭 검색 결과 조회
         * @param {ApiV1IntegrateSearchPostRequest} apiV1IntegrateSearchPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrateSearchPost(apiV1IntegrateSearchPostRequest: ApiV1IntegrateSearchPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1IntegrateSearchPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrateSearchPost(apiV1IntegrateSearchPostRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrateApi - factory interface
 * @export
 */
export const IntegrateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrateApiFp(configuration)
    return {
        /**
         * 통합검색 모임 컬렉션 지역 범위 필터
         * @summary 통합검색 모임 컬렉션 지역 범위 필터
         * @param {string} regionId 
         * @param {'view'} rangeType 
         * @param {'geojson'} [polygonFormat] 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrateKarrotGroupRegionRangesGet(regionId: string, rangeType: 'view', polygonFormat?: 'geojson', xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1IntegrateKarrotGroupRegionRangesGet200Response> {
            return localVarFp.apiV1IntegrateKarrotGroupRegionRangesGet(regionId, rangeType, polygonFormat, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 통합탭 검색 결과 조회
         * @summary 통합탭 검색 결과 조회
         * @param {ApiV1IntegrateSearchPostRequest} apiV1IntegrateSearchPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrateSearchPost(apiV1IntegrateSearchPostRequest: ApiV1IntegrateSearchPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1IntegrateSearchPost200Response> {
            return localVarFp.apiV1IntegrateSearchPost(apiV1IntegrateSearchPostRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1IntegrateKarrotGroupRegionRangesGet operation in IntegrateApi.
 * @export
 * @interface IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGetRequest
 */
export interface IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly regionId: string

    /**
     * 
     * @type {'view'}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly rangeType: 'view'

    /**
     * 
     * @type {'geojson'}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly polygonFormat?: 'geojson'

    /**
     * x-auth-token
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xKarrotSessionId?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGet
     */
    readonly xForwardedFor?: string
}

/**
 * Request parameters for apiV1IntegrateSearchPost operation in IntegrateApi.
 * @export
 * @interface IntegrateApiApiV1IntegrateSearchPostRequest
 */
export interface IntegrateApiApiV1IntegrateSearchPostRequest {
    /**
     * 
     * @type {ApiV1IntegrateSearchPostRequest}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly apiV1IntegrateSearchPostRequest: ApiV1IntegrateSearchPostRequest

    /**
     * x-auth-token
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xKarrotSessionId?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof IntegrateApiApiV1IntegrateSearchPost
     */
    readonly xForwardedFor?: string
}

/**
 * IntegrateApi - object-oriented interface
 * @export
 * @class IntegrateApi
 * @extends {BaseAPI}
 */
export class IntegrateApi extends BaseAPI {
    /**
     * 통합검색 모임 컬렉션 지역 범위 필터
     * @summary 통합검색 모임 컬렉션 지역 범위 필터
     * @param {IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrateApi
     */
    public apiV1IntegrateKarrotGroupRegionRangesGet(requestParameters: IntegrateApiApiV1IntegrateKarrotGroupRegionRangesGetRequest, options?: AxiosRequestConfig) {
        return IntegrateApiFp(this.configuration).apiV1IntegrateKarrotGroupRegionRangesGet(requestParameters.regionId, requestParameters.rangeType, requestParameters.polygonFormat, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 통합탭 검색 결과 조회
     * @summary 통합탭 검색 결과 조회
     * @param {IntegrateApiApiV1IntegrateSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrateApi
     */
    public apiV1IntegrateSearchPost(requestParameters: IntegrateApiApiV1IntegrateSearchPostRequest, options?: AxiosRequestConfig) {
        return IntegrateApiFp(this.configuration).apiV1IntegrateSearchPost(requestParameters.apiV1IntegrateSearchPostRequest, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }
}
