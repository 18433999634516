/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInner } from './fleamarket-search-success-response-documents-inner-component-catalog-advertisement-overflows-inner';
import { FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdInfo } from './fleamarket-search-success-response-documents-inner-component-neighbor-ad-info';
import { FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdInfoButtonLogging } from './fleamarket-search-success-response-documents-inner-component-neighbor-ad-info-button-logging';
import { ProtobufTimestamp } from './protobuf-timestamp';
import { SearchBigPicture } from './search-big-picture';

/**
 * 
 * @export
 * @interface FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
 */
export interface FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd {
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'slot': string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'targetUri': string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'regionId': string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'firstImageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'status'?: FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'watchCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'chatRoomCount'?: number;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'publishedAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'createdAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {SearchBigPicture}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'firstImage'?: SearchBigPicture;
    /**
     * 
     * @type {FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdInfo}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'info'?: FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdInfo;
    /**
     * 
     * @type {Array<FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInner>}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'overflows'?: Array<FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInner>;
    /**
     * 
     * @type {FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdInfoButtonLogging}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAd
     */
    'logging'?: FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdInfoButtonLogging;
}

export const FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum = {
    UNSPECIFIED: 'NEIGHBOR_AD_STATUS_UNSPECIFIED',
    ONGOING: 'NEIGHBOR_AD_STATUS_ONGOING',
    RESERVED: 'NEIGHBOR_AD_STATUS_RESERVED',
    CLOSED: 'NEIGHBOR_AD_STATUS_CLOSED'
} as const;

export type FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum = typeof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum[keyof typeof FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum];


