/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 키워드 알림 등록 실패
 * @export
 * @interface FleamarketCreateKeywordErrorResponse
 */
export interface FleamarketCreateKeywordErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof FleamarketCreateKeywordErrorResponse
     */
    'type': FleamarketCreateKeywordErrorResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FleamarketCreateKeywordErrorResponse
     */
    'message': string;
}

export const FleamarketCreateKeywordErrorResponseTypeEnum = {
    FLEAMARKET_KEYWORD_NOT_ALLOWED: 'ERROR_TYPE_FLEAMARKET_KEYWORD_NOT_ALLOWED',
    FLEAMARKET_KEYWORD_EXCEED_LIMIT: 'ERROR_TYPE_FLEAMARKET_KEYWORD_EXCEED_LIMIT',
    FLEAMARKET_KEYWORD_ALREADY_EXISTS: 'ERROR_TYPE_FLEAMARKET_KEYWORD_ALREADY_EXISTS',
    UNSPECIFIED: 'ERROR_TYPE_UNSPECIFIED'
} as const;

export type FleamarketCreateKeywordErrorResponseTypeEnum = typeof FleamarketCreateKeywordErrorResponseTypeEnum[keyof typeof FleamarketCreateKeywordErrorResponseTypeEnum];


