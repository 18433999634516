/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FleamarketSearchSuccessResponseDocumentsInnerMeta } from './fleamarket-search-success-response-documents-inner-meta';
import { IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponent } from './integrate-search-success-response-dsp-advertisement-docs-inner-component';

/**
 * 
 * @export
 * @interface IntegrateSearchSuccessResponseDspAdvertisementDocsInner
 */
export interface IntegrateSearchSuccessResponseDspAdvertisementDocsInner {
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseDspAdvertisementDocsInner
     */
    'componentType': IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponentTypeEnum;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponent}
     * @memberof IntegrateSearchSuccessResponseDspAdvertisementDocsInner
     */
    'component': IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponent;
    /**
     * 
     * @type {FleamarketSearchSuccessResponseDocumentsInnerMeta}
     * @memberof IntegrateSearchSuccessResponseDspAdvertisementDocsInner
     */
    'meta': FleamarketSearchSuccessResponseDocumentsInnerMeta;
}

export const IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponentTypeEnum = {
    UNSPECIFIED: 'DSPAD_COMPONENT_TYPE_UNSPECIFIED',
    CATALOG_ADVERTISEMENT: 'DSPAD_COMPONENT_TYPE_CATALOG_ADVERTISEMENT',
    CAROUSEL_ADVERTISEMENT: 'DSPAD_COMPONENT_TYPE_CAROUSEL_ADVERTISEMENT'
} as const;

export type IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponentTypeEnum = typeof IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponentTypeEnum[keyof typeof IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponentTypeEnum];


