/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiV1RecentSearchesDelete200Response } from '../models';
// @ts-ignore
import { ApiV1RecentSearchesDelete200Response1 } from '../models';
// @ts-ignore
import { ApiV1RecentSearchesQueryDelete200Response } from '../models';
// @ts-ignore
import { ApiV1RecentSearchesQueryDeleteRequest } from '../models';
// @ts-ignore
import { ApiV1RecentSearchesShortcutDelete200Response } from '../models';
// @ts-ignore
import { ApiV1RecentSearchesShortcutDeleteRequest } from '../models';
/**
 * RecentSearchApi - axios parameter creator
 * @export
 */
export const RecentSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 최근 검색어 전체 삭제
         * @summary 최근 검색어 전체 삭제
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecentSearchesDelete: async (xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/recent-searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 최근 검색 목록
         * @summary 최근 검색 목록 조회
         * @param {string} regionId 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecentSearchesGet: async (regionId: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('apiV1RecentSearchesGet', 'regionId', regionId)
            const localVarPath = `/api/v1/recent-searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 최근 검색어 단건 삭제
         * @summary 최근 검색어 단건 삭제
         * @param {ApiV1RecentSearchesQueryDeleteRequest} apiV1RecentSearchesQueryDeleteRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecentSearchesQueryDelete: async (apiV1RecentSearchesQueryDeleteRequest: ApiV1RecentSearchesQueryDeleteRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiV1RecentSearchesQueryDeleteRequest' is not null or undefined
            assertParamExists('apiV1RecentSearchesQueryDelete', 'apiV1RecentSearchesQueryDeleteRequest', apiV1RecentSearchesQueryDeleteRequest)
            const localVarPath = `/api/v1/recent-searches/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV1RecentSearchesQueryDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 최근 검색 숏컷 단건 삭제
         * @summary 최근 검색 숏컷 단건 삭제
         * @param {ApiV1RecentSearchesShortcutDeleteRequest} apiV1RecentSearchesShortcutDeleteRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecentSearchesShortcutDelete: async (apiV1RecentSearchesShortcutDeleteRequest: ApiV1RecentSearchesShortcutDeleteRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiV1RecentSearchesShortcutDeleteRequest' is not null or undefined
            assertParamExists('apiV1RecentSearchesShortcutDelete', 'apiV1RecentSearchesShortcutDeleteRequest', apiV1RecentSearchesShortcutDeleteRequest)
            const localVarPath = `/api/v1/recent-searches/shortcut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV1RecentSearchesShortcutDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecentSearchApi - functional programming interface
 * @export
 */
export const RecentSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecentSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 최근 검색어 전체 삭제
         * @summary 최근 검색어 전체 삭제
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecentSearchesDelete(xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1RecentSearchesDelete200Response1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecentSearchesDelete(xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 최근 검색 목록
         * @summary 최근 검색 목록 조회
         * @param {string} regionId 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecentSearchesGet(regionId: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1RecentSearchesDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecentSearchesGet(regionId, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 최근 검색어 단건 삭제
         * @summary 최근 검색어 단건 삭제
         * @param {ApiV1RecentSearchesQueryDeleteRequest} apiV1RecentSearchesQueryDeleteRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecentSearchesQueryDelete(apiV1RecentSearchesQueryDeleteRequest: ApiV1RecentSearchesQueryDeleteRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1RecentSearchesQueryDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecentSearchesQueryDelete(apiV1RecentSearchesQueryDeleteRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 최근 검색 숏컷 단건 삭제
         * @summary 최근 검색 숏컷 단건 삭제
         * @param {ApiV1RecentSearchesShortcutDeleteRequest} apiV1RecentSearchesShortcutDeleteRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RecentSearchesShortcutDelete(apiV1RecentSearchesShortcutDeleteRequest: ApiV1RecentSearchesShortcutDeleteRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1RecentSearchesShortcutDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RecentSearchesShortcutDelete(apiV1RecentSearchesShortcutDeleteRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecentSearchApi - factory interface
 * @export
 */
export const RecentSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecentSearchApiFp(configuration)
    return {
        /**
         * 최근 검색어 전체 삭제
         * @summary 최근 검색어 전체 삭제
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecentSearchesDelete(xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1RecentSearchesDelete200Response1> {
            return localVarFp.apiV1RecentSearchesDelete(xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 최근 검색 목록
         * @summary 최근 검색 목록 조회
         * @param {string} regionId 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecentSearchesGet(regionId: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1RecentSearchesDelete200Response> {
            return localVarFp.apiV1RecentSearchesGet(regionId, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 최근 검색어 단건 삭제
         * @summary 최근 검색어 단건 삭제
         * @param {ApiV1RecentSearchesQueryDeleteRequest} apiV1RecentSearchesQueryDeleteRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecentSearchesQueryDelete(apiV1RecentSearchesQueryDeleteRequest: ApiV1RecentSearchesQueryDeleteRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1RecentSearchesQueryDelete200Response> {
            return localVarFp.apiV1RecentSearchesQueryDelete(apiV1RecentSearchesQueryDeleteRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 최근 검색 숏컷 단건 삭제
         * @summary 최근 검색 숏컷 단건 삭제
         * @param {ApiV1RecentSearchesShortcutDeleteRequest} apiV1RecentSearchesShortcutDeleteRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RecentSearchesShortcutDelete(apiV1RecentSearchesShortcutDeleteRequest: ApiV1RecentSearchesShortcutDeleteRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1RecentSearchesShortcutDelete200Response> {
            return localVarFp.apiV1RecentSearchesShortcutDelete(apiV1RecentSearchesShortcutDeleteRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1RecentSearchesDelete operation in RecentSearchApi.
 * @export
 * @interface RecentSearchApiApiV1RecentSearchesDeleteRequest
 */
export interface RecentSearchApiApiV1RecentSearchesDeleteRequest {
    /**
     * x-auth-token
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xKarrotSessionId?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesDelete
     */
    readonly xForwardedFor?: string
}

/**
 * Request parameters for apiV1RecentSearchesGet operation in RecentSearchApi.
 * @export
 * @interface RecentSearchApiApiV1RecentSearchesGetRequest
 */
export interface RecentSearchApiApiV1RecentSearchesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly regionId: string

    /**
     * x-auth-token
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xKarrotSessionId?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesGet
     */
    readonly xForwardedFor?: string
}

/**
 * Request parameters for apiV1RecentSearchesQueryDelete operation in RecentSearchApi.
 * @export
 * @interface RecentSearchApiApiV1RecentSearchesQueryDeleteRequest
 */
export interface RecentSearchApiApiV1RecentSearchesQueryDeleteRequest {
    /**
     * 
     * @type {ApiV1RecentSearchesQueryDeleteRequest}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly apiV1RecentSearchesQueryDeleteRequest: ApiV1RecentSearchesQueryDeleteRequest

    /**
     * x-auth-token
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xKarrotSessionId?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesQueryDelete
     */
    readonly xForwardedFor?: string
}

/**
 * Request parameters for apiV1RecentSearchesShortcutDelete operation in RecentSearchApi.
 * @export
 * @interface RecentSearchApiApiV1RecentSearchesShortcutDeleteRequest
 */
export interface RecentSearchApiApiV1RecentSearchesShortcutDeleteRequest {
    /**
     * 
     * @type {ApiV1RecentSearchesShortcutDeleteRequest}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly apiV1RecentSearchesShortcutDeleteRequest: ApiV1RecentSearchesShortcutDeleteRequest

    /**
     * x-auth-token
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xKarrotSessionId?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof RecentSearchApiApiV1RecentSearchesShortcutDelete
     */
    readonly xForwardedFor?: string
}

/**
 * RecentSearchApi - object-oriented interface
 * @export
 * @class RecentSearchApi
 * @extends {BaseAPI}
 */
export class RecentSearchApi extends BaseAPI {
    /**
     * 최근 검색어 전체 삭제
     * @summary 최근 검색어 전체 삭제
     * @param {RecentSearchApiApiV1RecentSearchesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchApi
     */
    public apiV1RecentSearchesDelete(requestParameters: RecentSearchApiApiV1RecentSearchesDeleteRequest = {}, options?: AxiosRequestConfig) {
        return RecentSearchApiFp(this.configuration).apiV1RecentSearchesDelete(requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 최근 검색 목록
     * @summary 최근 검색 목록 조회
     * @param {RecentSearchApiApiV1RecentSearchesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchApi
     */
    public apiV1RecentSearchesGet(requestParameters: RecentSearchApiApiV1RecentSearchesGetRequest, options?: AxiosRequestConfig) {
        return RecentSearchApiFp(this.configuration).apiV1RecentSearchesGet(requestParameters.regionId, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 최근 검색어 단건 삭제
     * @summary 최근 검색어 단건 삭제
     * @param {RecentSearchApiApiV1RecentSearchesQueryDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchApi
     */
    public apiV1RecentSearchesQueryDelete(requestParameters: RecentSearchApiApiV1RecentSearchesQueryDeleteRequest, options?: AxiosRequestConfig) {
        return RecentSearchApiFp(this.configuration).apiV1RecentSearchesQueryDelete(requestParameters.apiV1RecentSearchesQueryDeleteRequest, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 최근 검색 숏컷 단건 삭제
     * @summary 최근 검색 숏컷 단건 삭제
     * @param {RecentSearchApiApiV1RecentSearchesShortcutDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchApi
     */
    public apiV1RecentSearchesShortcutDelete(requestParameters: RecentSearchApiApiV1RecentSearchesShortcutDeleteRequest, options?: AxiosRequestConfig) {
        return RecentSearchApiFp(this.configuration).apiV1RecentSearchesShortcutDelete(requestParameters.apiV1RecentSearchesShortcutDeleteRequest, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }
}
