/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { IntegrateSearchSuccessResponseJobDocsInnerWorkplaceLocationCoordinates } from './integrate-search-success-response-job-docs-inner-workplace-location-coordinates';
import { ProtobufTimestamp } from './protobuf-timestamp';
import { SearchBigPicture } from './search-big-picture';

/**
 * 
 * @export
 * @interface IntegrateSearchSuccessResponseJobDocsInner
 */
export interface IntegrateSearchSuccessResponseJobDocsInner {
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'cursor'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'content': string;
    /**
     * 
     * @type {Array<SearchBigPicture>}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'images'?: Array<SearchBigPicture>;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'salaryType'?: IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'salary'?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'workPeriod'?: IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'workDays'?: Array<IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'isWorkPeriodNegotiable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'isWorkTimeNegotiable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'isWorkDaysNegotiable'?: boolean;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'publishedAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'lastBringUpDate'?: ProtobufTimestamp;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'targetUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'globalId': string;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'jobReviewCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'authorBadges'?: Array<string>;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseJobDocsInnerWorkplaceLocationCoordinates}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'workplaceLocationCoordinates'?: IntegrateSearchSuccessResponseJobDocsInnerWorkplaceLocationCoordinates;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'advertisementLivenessCheckedAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'advertisementRegionIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'startWorkTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'endWorkTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'index'?: number;
    /**
     * 
     * @type {Array<ProtobufTimestamp>}
     * @memberof IntegrateSearchSuccessResponseJobDocsInner
     */
    'workDates'?: Array<ProtobufTimestamp>;
}

export const IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum = {
    UNSPECIFIED: 'JOB_SALARY_TYPE_UNSPECIFIED',
    DAILY: 'JOB_SALARY_TYPE_DAILY',
    HOURLY: 'JOB_SALARY_TYPE_HOURLY',
    MONTHLY: 'JOB_SALARY_TYPE_MONTHLY',
    PER_CASE: 'JOB_SALARY_TYPE_PER_CASE'
} as const;

export type IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum = typeof IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum[keyof typeof IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum];
export const IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum = {
    UNSPECIFIED: 'JOB_WORK_PERIOD_UNSPECIFIED',
    LESS_THAN_A_MONTH: 'JOB_WORK_PERIOD_LESS_THAN_A_MONTH',
    MORE_THAN_A_MONTH: 'JOB_WORK_PERIOD_MORE_THAN_A_MONTH'
} as const;

export type IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum = typeof IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum[keyof typeof IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum];
export const IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum = {
    UNSPECIFIED: 'JOB_WORK_DAY_UNSPECIFIED',
    MON: 'JOB_WORK_DAY_MON',
    TUE: 'JOB_WORK_DAY_TUE',
    WED: 'JOB_WORK_DAY_WED',
    THU: 'JOB_WORK_DAY_THU',
    FRI: 'JOB_WORK_DAY_FRI',
    SAT: 'JOB_WORK_DAY_SAT',
    SUN: 'JOB_WORK_DAY_SUN'
} as const;

export type IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum = typeof IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum[keyof typeof IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum];


