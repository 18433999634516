/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ApiV1FleamarketSearchPostRequest
 */
export interface ApiV1FleamarketSearchPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'regionId': string;
    /**
     * 
     * @type {number}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'pageSize': number;
    /**
     * 
     * @type {string}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'pageToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'sort'?: ApiV1FleamarketSearchPostRequestSortEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'regionRange': ApiV1FleamarketSearchPostRequestRegionRangeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'categoryIds': Array<string>;
    /**
     * 가격이 이 값 이상인 게시글만 조회해요. 해당 필드는 IDL에서 double 타입이라 값이 없어도 서치서버에는 0으로 전달되기 때문에 기본값을 0으로 설정해요.
     * @type {number}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'priceGte'?: number;
    /**
     * 가격이 이 값 이하인 게시글만 조회해요. 해당 필드는 IDL에서 double 타입이라 값이 없어도 서치서버에는 0으로 전달되기 때문에 기본값을 0으로 설정해요.
     * @type {number}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'priceLte'?: number;
    /**
     * 거래중, 판매완료 제외할지 유무
     * @type {boolean}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'withoutCompleted': boolean;
    /**
     * true: 무료나눔의 게시글만 조회
     * @type {boolean}
     * @memberof ApiV1FleamarketSearchPostRequest
     */
    'isFreeShareEvent': boolean;
}

export const ApiV1FleamarketSearchPostRequestSortEnum = {
    UNSPECIFIED: 'SEARCH_SORT_UNSPECIFIED',
    RELEVANT: 'SEARCH_SORT_RELEVANT',
    RECENT: 'SEARCH_SORT_RECENT',
    PUBLISHED_AT_ASC: 'SEARCH_SORT_PUBLISHED_AT_ASC',
    PUBLISHED_AT_DESC: 'SEARCH_SORT_PUBLISHED_AT_DESC',
    PRICE_ASC: 'SEARCH_SORT_PRICE_ASC',
    PRICE_DESC: 'SEARCH_SORT_PRICE_DESC',
    SCORE_ASC: 'SEARCH_SORT_SCORE_ASC',
    SCORE_DESC: 'SEARCH_SORT_SCORE_DESC'
} as const;

export type ApiV1FleamarketSearchPostRequestSortEnum = typeof ApiV1FleamarketSearchPostRequestSortEnum[keyof typeof ApiV1FleamarketSearchPostRequestSortEnum];
export const ApiV1FleamarketSearchPostRequestRegionRangeEnum = {
    UNSPECIFIED: 'REGION_RANGE_UNSPECIFIED',
    NO_PUBLISH: 'REGION_RANGE_NO_PUBLISH',
    MY: 'REGION_RANGE_MY',
    ADJACENT: 'REGION_RANGE_ADJACENT',
    RANGE2: 'REGION_RANGE_RANGE2',
    RANGE3: 'REGION_RANGE_RANGE3'
} as const;

export type ApiV1FleamarketSearchPostRequestRegionRangeEnum = typeof ApiV1FleamarketSearchPostRequestRegionRangeEnum[keyof typeof ApiV1FleamarketSearchPostRequestRegionRangeEnum];


