/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiV1UserSearchPost200Response } from '../models';
// @ts-ignore
import { ApiV1UserSearchPostRequest } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 유저 검색 결과
         * @summary 유저 검색 결과
         * @param {ApiV1UserSearchPostRequest} apiV1UserSearchPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserSearchPost: async (apiV1UserSearchPostRequest: ApiV1UserSearchPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiV1UserSearchPostRequest' is not null or undefined
            assertParamExists('apiV1UserSearchPost', 'apiV1UserSearchPostRequest', apiV1UserSearchPostRequest)
            const localVarPath = `/api/v1/user/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV1UserSearchPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 유저 검색 결과
         * @summary 유저 검색 결과
         * @param {ApiV1UserSearchPostRequest} apiV1UserSearchPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserSearchPost(apiV1UserSearchPostRequest: ApiV1UserSearchPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1UserSearchPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserSearchPost(apiV1UserSearchPostRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 유저 검색 결과
         * @summary 유저 검색 결과
         * @param {ApiV1UserSearchPostRequest} apiV1UserSearchPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserSearchPost(apiV1UserSearchPostRequest: ApiV1UserSearchPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1UserSearchPost200Response> {
            return localVarFp.apiV1UserSearchPost(apiV1UserSearchPostRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xForwardedFor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1UserSearchPost operation in UserApi.
 * @export
 * @interface UserApiApiV1UserSearchPostRequest
 */
export interface UserApiApiV1UserSearchPostRequest {
    /**
     * 
     * @type {ApiV1UserSearchPostRequest}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly apiV1UserSearchPostRequest: ApiV1UserSearchPostRequest

    /**
     * x-auth-token
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xKarrotSessionId?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof UserApiApiV1UserSearchPost
     */
    readonly xForwardedFor?: string
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 유저 검색 결과
     * @summary 유저 검색 결과
     * @param {UserApiApiV1UserSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UserSearchPost(requestParameters: UserApiApiV1UserSearchPostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UserSearchPost(requestParameters.apiV1UserSearchPostRequest, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }
}
