/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { IntegrateSearchSuccessResponseAdvertisementDocsInnerBizProfile } from './integrate-search-success-response-advertisement-docs-inner-biz-profile';

/**
 * 
 * @export
 * @interface IntegrateSearchSuccessResponseAdvertisementDocsInner
 */
export interface IntegrateSearchSuccessResponseAdvertisementDocsInner {
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'impressionUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'clickUrl': string;
    /**
     * 
     * @type {number}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'slot'?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'userType'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'hideUrl': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'reportUrl': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'infoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'hideReasonUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'materialSourceType'?: IntegrateSearchSuccessResponseAdvertisementDocsInnerMaterialSourceTypeEnum;
    /**
     * 
     * @type {IntegrateSearchSuccessResponseAdvertisementDocsInnerBizProfile}
     * @memberof IntegrateSearchSuccessResponseAdvertisementDocsInner
     */
    'bizProfile': IntegrateSearchSuccessResponseAdvertisementDocsInnerBizProfile;
}

export const IntegrateSearchSuccessResponseAdvertisementDocsInnerMaterialSourceTypeEnum = {
    UNSPECIFIED: 'MATERIAL_SOURCE_TYPE_UNSPECIFIED',
    BUSINESS_POST: 'MATERIAL_SOURCE_TYPE_BUSINESS_POST',
    BUSINESS_PROFILE: 'MATERIAL_SOURCE_TYPE_BUSINESS_PROFILE',
    COMMERCE: 'MATERIAL_SOURCE_TYPE_COMMERCE',
    REALTY: 'MATERIAL_SOURCE_TYPE_REALTY',
    TOWN_JOB: 'MATERIAL_SOURCE_TYPE_TOWN_JOB',
    TOWN_ARTICLE: 'MATERIAL_SOURCE_TYPE_TOWN_ARTICLE',
    CLASS: 'MATERIAL_SOURCE_TYPE_CLASS',
    FARM: 'MATERIAL_SOURCE_TYPE_FARM',
    OUTLINK: 'MATERIAL_SOURCE_TYPE_OUTLINK',
    BLOG: 'MATERIAL_SOURCE_TYPE_BLOG',
    CATALOG_PRODUCT: 'MATERIAL_SOURCE_TYPE_CATALOG_PRODUCT',
    VIDEO: 'MATERIAL_SOURCE_TYPE_VIDEO',
    CATALOG_PRODUCT_SET: 'MATERIAL_SOURCE_TYPE_CATALOG_PRODUCT_SET',
    OUTLINK_VIDEO: 'MATERIAL_SOURCE_TYPE_OUTLINK_VIDEO',
    BUSINESS_PROFILE_VIDEO: 'MATERIAL_SOURCE_TYPE_BUSINESS_PROFILE_VIDEO'
} as const;

export type IntegrateSearchSuccessResponseAdvertisementDocsInnerMaterialSourceTypeEnum = typeof IntegrateSearchSuccessResponseAdvertisementDocsInnerMaterialSourceTypeEnum[keyof typeof IntegrateSearchSuccessResponseAdvertisementDocsInnerMaterialSourceTypeEnum];


