/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 통합검색 내 모임 검색을 위한 옵션값
 * @export
 * @interface ApiV1IntegrateSearchPostRequestKarrotGroup
 */
export interface ApiV1IntegrateSearchPostRequestKarrotGroup {
    /**
     * 모임 가입 가능 지역 범위. 기본값: 전국
     * @type {string}
     * @memberof ApiV1IntegrateSearchPostRequestKarrotGroup
     */
    'regionRange'?: ApiV1IntegrateSearchPostRequestKarrotGroupRegionRangeEnum;
    /**
     * 연령 최소값. 설정하지 않는 경우 하한 제한 없음
     * @type {number}
     * @memberof ApiV1IntegrateSearchPostRequestKarrotGroup
     */
    'ageGte'?: number;
    /**
     * 연령 최대값. 설정하지 않는 경우 상한 제한 없음
     * @type {number}
     * @memberof ApiV1IntegrateSearchPostRequestKarrotGroup
     */
    'ageLte'?: number;
}

export const ApiV1IntegrateSearchPostRequestKarrotGroupRegionRangeEnum = {
    UNSPECIFIED: 'COMMUNITY_GROUP_JOINABLE_REGION_RANGE_UNSPECIFIED',
    MY: 'COMMUNITY_GROUP_JOINABLE_REGION_RANGE_MY',
    ADJACENT: 'COMMUNITY_GROUP_JOINABLE_REGION_RANGE_ADJACENT',
    RANGE2: 'COMMUNITY_GROUP_JOINABLE_REGION_RANGE_RANGE2',
    RANGE3: 'COMMUNITY_GROUP_JOINABLE_REGION_RANGE_RANGE3',
    UNLIMITED: 'COMMUNITY_GROUP_JOINABLE_REGION_RANGE_UNLIMITED'
} as const;

export type ApiV1IntegrateSearchPostRequestKarrotGroupRegionRangeEnum = typeof ApiV1IntegrateSearchPostRequestKarrotGroupRegionRangeEnum[keyof typeof ApiV1IntegrateSearchPostRequestKarrotGroupRegionRangeEnum];


